<template>
    <div class="documents-page">
        <h1 v-if="filter === 'current'">{{ $t('company.documents.current.current_title') }}</h1>
        <h1 v-if="filter === 'archive'">{{ $t('company.documents.current.archive_title') }}</h1>
        <div class="filter">
            <button
                :class="{ active: filter === 'current' }"
                class="at-btn at-btn--primary at-btn--large action-button"
                @click="currentScope"
            >
                {{ $t('company.documents.current.buttons.current') }}
            </button>
            <button
                :class="{ active: filter === 'archive' }"
                class="at-btn at-btn--primary at-btn--large action-button"
                @click="archivedScope"
            >
                {{ $t('company.documents.current.buttons.archive') }}
            </button>
        </div>
        <input
            v-model="searchQuery"
            type="text"
            :placeholder="$t('company.documents.current.search')"
            class="search-bar"
        />
        <ul class="document-list">
            <li v-for="document in documents" :key="document.attributes.id" class="document-item">
                <div class="document-info">
                    <h3>{{ document.attributes.name }} (v{{ document.attributes.version }})</h3>
                    <p>
                        <strong>{{ $t('company.documents.current.fields.company') }}</strong>
                        {{ document.attributes.company.attributes.name }}
                    </p>
                    <p>
                        <strong>{{ $t('company.documents.current.fields.partner_company') }}</strong>
                        {{ document.attributes.partner_company.attributes.name }}
                    </p>
                    <p>
                        <strong>{{ $t('company.documents.current.fields.partner_company_approved') }}</strong>
                        {{ document.attributes.partner_company_approved }}
                    </p>
                </div>
                <button
                    class="view-button at-btn at-btn--primary at-btn--large action-button"
                    @click="$router.push({ path: `view/${document.attributes.id}` })"
                >
                    {{ $t('company.documents.current.buttons.view') }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Service from '../../document/services/document.service';

    export default {
        data() {
            return {
                filter: 'current',
                archived: false,
                searchQuery: '',
                documents: [],
                service: new Service(),
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
        },
        created() {
            this.fetchDocuments();
        },
        methods: {
            viewDocument(documentId) {
                alert(`Viewing details for document ID: ${documentId}`);
            },
            async fetchDocuments() {
                try {
                    const { data } = await this.service.getAll({
                        partner_company_id: this.company.id,
                        archived: this.archived,
                        partner_company_approved: true,
                    });
                    this.documents = data.data;
                } catch (error) {
                    console.log('Error documents request', error);
                }
            },
            archivedScope() {
                this.filter = 'archive';
                this.archived = true;
            },
            currentScope() {
                this.filter = 'current';
                this.archived = false;
            },
        },
        watch: {
            filter(_value) {
                this.fetchDocuments();
            },
        },
    };
</script>

<style scoped lang="scss">
    .documents-page {
        max-width: 800px;
        margin: 50px auto;
        padding: 20px;
        background: linear-gradient(135deg, #f7f9fc, #e2ebf0);
        border-radius: 12px;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        font-family: Arial, sans-serif;
        color: #333;

        .theme-dark & {
            background: #222;
            color: #ffa500;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
        }
    }
    .active {
        color: #ffa500;

        .theme-dark & {
            color: #c4c4cf;
            border-color: #c4c4cf;
        }
    }

    h1 {
        text-align: center;
        font-size: 2em;
        margin-bottom: 20px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .filter {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 20px;
    }

    .search-bar {
        display: block;
        width: 100%;
        padding: 12px 16px;
        font-size: 14px;
        border: 1px solid #ddd;
        border-radius: 8px;
        transition: border-color 0.3s, box-shadow 0.3s;
        margin-bottom: 10px;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            outline: none;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .document-list {
        list-style: none;
        padding: 0;

        .document-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            margin-bottom: 15px;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s ease;

            &:hover {
                transform: translateY(-4px);
                box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
            }

            .theme-dark & {
                background-color: #333;
                border-color: #555;
                color: #ffa500;
            }

            .document-info {
                flex: 1;

                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                    color: #333;

                    .theme-dark & {
                        color: #ffa500;
                    }
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    color: #555;

                    .theme-dark & {
                        color: #ffa500;
                    }
                }

                strong {
                    color: #333;

                    .theme-dark & {
                        color: #ffa500;
                    }
                }
            }
        }
    }
</style>
