export const ModuleConfig = {
    routerPrefix: 'settings',
    loadOrder: 25,
    moduleName: 'Services',
};

export function init(context, router) {
    // context.addCompanySection(require('./sections/services').default(context, router));

    // context.addLocalizationData({
    //     en: require('./locales/en'),
    //     ru: require('./locales/ru'),
    //     ua: require('./locales/ua'),
    //     pl: require('./locales/pl'),
    //     de: require('./locales/de'),
    //     fr: require('./locales/fr'),
    // });

    return context;
}
